<div class="card">
  <div class="card-header">
    {{ title }} <span *ngIf="estimate">({{ estimate }})</span>
  </div>
  <div class="card-body">
    <div class="empty" *ngIf="!markers"></div>
    <agm-map
      *ngIf="markers"
      [zoom]="setZoom ? setZoom : 18"
      [fitBounds]="keepFitting && !singleMarker && markers.length > 0"
      [latitude]="
        setLat ? setLat : markers[0] ? markers[0].latitude : latitudeBase
      "
      [longitude]="
        setLong ? setLong : markers[0] ? markers[0].longitude : longitudeBase
      "
      (mapReady)="saveMap($event)"
      (boundsChange)="boundsChanged($event)"
      (mapClick)="mapClicked($event)"
    >
      <agm-marker
        *ngFor="let marker of markers"
        [latitude]="marker.latitude"
        [longitude]="marker.longitude"
        [label]="marker.id"
        [iconUrl]="marker.url"
        [agmFitBounds]="true"
        [openInfoWindow]="false"
        [markerDraggable]="marker.markerDraggable"
        (dragEnd)="markerMoved($event, marker)"
        (markerClick)="markerClicked(marker, infoWindow)"
        (mouseOver)="onMouseOver(infoWindow, $event)"
        (mouseOut)="onMouseOut(infoWindow, $event)"
      >
        <agm-info-window #infoWindow>
          <ng-container *ngFor="let t of marker.infoText; index as i">
            <ng-container *ngIf="openInNewWindow">
              <a routerLink="/video/viewer/{{ t }}" target="_blank">
                {{ t }}
              </a>
            </ng-container>
            <ng-container *ngIf="!openInNewWindow">
              <a routerLink="/video/viewer/{{ t }}">
                {{ t }}
              </a>
            </ng-container>
            <br *ngIf="i < marker.infoText.length - 1" />
          </ng-container>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>
