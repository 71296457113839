import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from './app.config.model';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {

    public static settings: IAppConfig;

    constructor(private http: HttpClient) { }
    load() {
        //console.log("Settings Loading");
        const jsonFile = `assets/config.${environment.name}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
                //console.log("Settings Loaded");
                AppConfig.settings = <IAppConfig>response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}