import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AgmCoreModule } from "@agm/core";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { MultiselectDropdownModule } from "angular-2-dropdown-multiselect";
import { FormsModule } from "@angular/forms";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PlatformModule } from "@angular/cdk/platform";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from "./app.component";

// Import containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER } from "@angular/core";
import { AppConfig } from "./app.config";

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts";
import { NgxPopper } from "angular-popper";
import { SAVER, getSaver } from "./video/saver.provider";
import { LogViewerComponent } from "./log-viewer/log-viewer.component";
import { EditUsersComponent } from "./edit-users/edit-users.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { FlashMessageComponent } from "./flash-message/flash-message.component";
import { FlashMessageService } from "./flash-message/flash-message.service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { StreamConfigurationComponent } from "./stream-configuration/stream-configuration.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MapviewerComponent } from "./mapviewer/mapviewer.component";
import { MapPageComponent } from "./map-page/map-page.component";
import { InfoPopupComponent } from "./info-popup/info-popup.component";
import { FtpServerAccessComponent } from './ftp-server-access/ftp-server-access.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBoqqqp1JIbNgobBPwSBRXDnGkjdbvEtYk",
    }),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    NgxPopper,
    NgMultiSelectDropDownModule.forRoot(),
    MultiselectDropdownModule,
    AngularMultiSelectModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    PlatformModule,
    MatCheckboxModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    LogViewerComponent,
    EditUsersComponent,
    FlashMessageComponent,
    StreamConfigurationComponent,
    MapviewerComponent,
    MapPageComponent,
    InfoPopupComponent,
    FtpServerAccessComponent,
  ],
  exports: [FlashMessageComponent],
  providers: [
    FlashMessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    { provide: SAVER, useFactory: getSaver },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
