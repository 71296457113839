import { Component, OnInit, ViewChild } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
  HttpRequest,
} from "@angular/common/http";
import * as moment from "moment";
import { Camera, Settings, Permission, User, Stream } from "../video/video";
import { VideoService } from "../video/video.service";
import { FlashMessageComponent } from "../flash-message/flash-message.component";
import { FlashMessageService } from "../flash-message/flash-message.service";

@Component({
  selector: "app-stream-configuration",
  templateUrl: "./stream-configuration.component.html",
  styleUrls: ["./stream-configuration.component.css"],
})
export class StreamConfigurationComponent implements OnInit {
  loadTime = moment().unix();
  permissions: Permission;
  changesMadeLive;
  changesMadeRecord;
  selectedStream;
  streamData;
  permittedStreamData;
  showRecordOptions = false;
  profileDetails = {} as any;
  cameraStreamDetails = {} as any;
  recordStream = {} as any;
  liveStream = {} as any;
  chosenLiveStream = "";
  chosenRecordStream = "";
  liveOptions = {
    resolutions: [],
    bitrates: [],
    buffering: false,
    res: null,
    bitrate: null,
  };
  recordOptions = {
    resolutions: [],
    bitrates: [],
    buffering: false,
    res: null,
    bitrate: null,
  };
  streamResolutions;
  bitRates;

  @ViewChild("fmDialog", { static: true })
  public fmDialog: FlashMessageComponent;

  constructor(
    private flashService: FlashMessageService,
    private http: HttpClient,
    private videoService: VideoService
  ) {}

  ngOnInit(): void {
    var _this = this;
    _this.selectedStream = { id: "" } as any;
    _this.flashService.register(_this.fmDialog);
    _this.videoService.getPermissionsList().subscribe((p) => {
      _this.permissions = p;
      //if (p.superuser) _this.showRecordOptions = true;
      _this.http.get<any>("/db/camera").subscribe((x) => {
        _this.permittedStreamData = x
          .filter(
            (y) =>
              p.superuser ||
              p.permissions.includes("allcameras") ||
              p.cameras.includes(y.id)
          )
          .sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          });
        _this.streamData = _this.permittedStreamData;
      });
      _this.http.get<any>("/db/stream_profile").subscribe((s) => {
        _this.streamResolutions = {
          live: [],
          record: [],
          live_use: null,
          record_use: null,
        };
        _this.bitRates = {
          live: [],
          record: [],
        };
        s.forEach((x) => {
          _this.profileDetails[x.id] = x;
          var type = x.id.split("-")[0].substring(0, 2);
          var liveProfile = x.id.split("-")[1] == "live";
          var resoRef = x.width + "x" + x.height;
          var resoDisplay =
            x.id.split("-")[0] + " (" + x.width + "x" + x.height + ")";
          var brValue = x.target_bps;
          var brValueText;
          if (brValue < 1024 * 1024) brValueText = brValue / 1024 + "kbps";
          else {
            brValueText = Math.round(brValue / 104857.6) / 10.0 + "Mbps";
          }
          var brDisplay = x.id.split("-")[2] + " (" + brValueText + ")";
          var streamItem = {
            type: x.id.split("-")[0].substring(0, 2),
            reference: resoRef,
            display: resoDisplay,
          };
          var brItem = {
            value: brValue,
            display: brDisplay,
          };
          if (liveProfile) {
            if (_this.uniqueReso(streamItem, _this.streamResolutions.live))
              _this.streamResolutions.live.push(streamItem);
            if (_this.uniqueBR(brItem, _this.bitRates.live))
              _this.bitRates.live.push(brItem);
          } else {
            if (_this.uniqueReso(streamItem, _this.streamResolutions.record))
              _this.streamResolutions.record.push(streamItem);
            if (_this.uniqueBR(brItem, _this.bitRates.record))
              _this.bitRates.record.push(brItem);
          }
        });
        _this.streamResolutions.live.sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          var aRefWidth = parseInt(a.reference.split("x")[0]);
          var bRefWidth = parseInt(b.reference.split("x")[0]);
          if (aRefWidth < bRefWidth) return -1;
          if (aRefWidth > bRefWidth) return 1;
          return 0;
        });
        _this.bitRates.live.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        _this.streamResolutions.record.sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          var aRefWidth = parseInt(a.reference.split("x")[0]);
          var bRefWidth = parseInt(b.reference.split("x")[0]);
          if (aRefWidth < bRefWidth) return -1;
          if (aRefWidth > bRefWidth) return 1;
          return 0;
        });
        _this.bitRates.record.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
      });
    });
  }

  doubleClick(camStream) {
    var _this = this;
    _this.changesMadeLive = false;
    _this.changesMadeRecord = false;
    _this.chosenLiveStream = "";
    _this.chosenRecordStream = "";
    _this.selectedStream = camStream;
    _this.liveStream = _this.profileDetails[_this.selectedStream.live_profile];
    _this.recordStream =
      _this.profileDetails[_this.selectedStream.record_profile];
    _this.setStreamOptions(
      _this.liveStream,
      _this.recordStream,
      _this.selectedStream
    );
  }
  commitChanges(): void {
    var _this = this;
    var headers = {
      Prefer: "return=representation",
    };
    var submitChanges = {} as any;
    if (_this.chosenLiveStream.length > 0)
      if (_this.chosenLiveStream != _this.liveStream.id)
        submitChanges.live_profile = _this.chosenLiveStream;
    if (_this.chosenRecordStream.length > 0)
      if (_this.chosenRecordStream != _this.recordStream.id)
        submitChanges.record_profile = _this.chosenRecordStream;
    var updateArray = [];
    updateArray.push(submitChanges);
    if (_this.selectedStream)
      _this.http
        .patch<any>(
          "/db/camera?id=eq." + _this.selectedStream.id,
          updateArray,
          {
            headers,
          }
        )
        .subscribe(
          (res) => {
            if (res) {
              _this.selectedStream = Object.assign(
                _this.selectedStream,
                submitChanges
              );
              _this.liveStream =
                _this.profileDetails[_this.selectedStream.live_profile];
              _this.recordStream =
                _this.profileDetails[_this.selectedStream.record_profile];
              _this.changesMadeLive = false;
              _this.changesMadeRecord = false;
              if (submitChanges.live_profile)
                _this.http
                  .post<any>(
                    "/rtspserver/" + _this.selectedStream.id + "/restart",
                    null
                  )
                  .subscribe(
                    (res) => {},
                    (err) => {
                      _this.flashService
                        .showThis(
                          "Error Restarting Live Stream For '" +
                            _this.selectedStream.id +
                            "'",
                          err.statusText +
                            " : " +
                            (err.error.details ? err.error.details : err.error),
                          false
                        )
                        .then((res) => {
                          _this.flashService.resetModal();
                        })
                        .catch((error) => {
                          _this.flashService.resetModal();
                        });
                    }
                  );
              if (submitChanges.record_profile)
                _this.http
                  .post<any>(
                    "/recorder/" + _this.selectedStream.id + "/restart",
                    null
                  )
                  .subscribe(
                    (res) => {},
                    (err) => {
                      _this.flashService
                        .showThis(
                          "Error Restarting Recording Stream For '" +
                            _this.selectedStream.id +
                            "'",
                          err.statusText +
                            " : " +
                            (err.error.details ? err.error.details : err.error),
                          false
                        )
                        .then((res) => {
                          _this.flashService.resetModal();
                        })
                        .catch((error) => {
                          _this.flashService.resetModal();
                        });
                    }
                  );
            }
          },
          (e) => {
            _this.flashService
              .showThis(
                "Error Changing Streams For '" + _this.selectedStream.id + "'",
                e.statusText +
                  " : " +
                  (e.error.details ? e.error.details : e.error),
                false
              )
              .then((res) => {
                _this.flashService.resetModal();
              })
              .catch((err) => {
                _this.flashService.resetModal();
              });
          }
        );
  }

  setStreamOptions(liveStream, recStream, selectedStream) {
    var _this = this;
    if (liveStream) {
      _this.liveOptions.resolutions = _this.streamResolutions.live.filter(
        (x) => {
          return liveStream.id.substring(0, 2) == x.type;
        }
      );
      if (selectedStream.type.match("ubnt")) {
        _this.liveOptions.resolutions = _this.liveOptions.resolutions.filter(
          (x) =>
            x.reference.match("640x360") ||
            x.reference.match("1024x576") ||
            x.reference.match("1920x1080")
        );
      }
      _this.liveOptions.bitrates = _this.bitRates.live;
      _this.recordOptions.bitrates = _this.bitRates.record;

      _this.liveOptions.res = liveStream.width + "x" + liveStream.height;
      _this.liveOptions.bitrate = liveStream.target_bps;
      _this.liveOptions.buffering = liveStream.latency_ms > 0;
      _this.chosenLiveStream = _this.liveStream.id;
    }

    if (recStream) {
      _this.recordOptions.resolutions = _this.streamResolutions.record.filter(
        (x) => {
          return recStream.id.substring(0, 2) == x.type;
        }
      );
      if (selectedStream.type.match("ubnt")) {
        _this.recordOptions.resolutions = _this.recordOptions.resolutions.filter(
          (x) =>
            x.resoRef.match("640x360") ||
            x.resoRef.match("1024x576") ||
            x.resoRef.match("1920x1080")
        );
      }
      _this.recordOptions.res = recStream.width + "x" + recStream.height;
      _this.recordOptions.bitrate = recStream.target_bps;
      _this.recordOptions.buffering = recStream.latency_ms > 0;
      _this.chosenRecordStream = _this.recordStream.id;
    }
  }
  changeLiveProfile() {
    this.chosenLiveStream = this.changeProfile("live");
  }
  changeRecordProfile() {
    this.chosenRecordStream = this.changeProfile("record");
  }
  changeProfile(streamType) {
    var chosen;
    var options;
    var streamId;
    switch (streamType) {
      case "live": {
        options = this.liveOptions;
        streamId = this.liveStream.id;
        break;
      }
      case "record": {
        options = this.recordOptions;
        streamId = this.recordStream.id;
        break;
      }
    }
    for (var i = 0; i < options.resolutions.length; i++)
      if (options.res == options.resolutions[i].reference) {
        chosen = options.resolutions[i].display.split(" ")[0];
        break;
      }
    chosen += "-" + streamType + "-";
    for (var j = 0; j < options.bitrates.length; j++)
      if (options.bitrate == options.bitrates[j].value) {
        chosen += options.bitrates[j].display.split(" ")[0];
        break;
      }
    if (options.buffering) chosen += "-buffer";

    switch (streamType) {
      case "live": {
        this.changesMadeLive = chosen != streamId;
        break;
      }
      case "record": {
        this.changesMadeRecord = chosen != streamId;
        break;
      }
    }
    return chosen;
  }

  search(q: string): void {
    var _this = this;
    if (q == "") {
      _this.streamData = _this.permittedStreamData;
    } else {
      _this.streamData = _this.permittedStreamData.filter((s) =>
        s.id && typeof s.id === "string" ? s.id.includes(q) : false
      );
    }
  }
  reload(): void {
    var _this = this;
    if (_this.selectedStream.id.length > 0)
      _this.doubleClick(_this.selectedStream);
    else {
      //var id = _this.editingStream.id;
      _this.cancelChanges();
      //_this.editingStream.id = id;
    }
  }
  cancelChanges(): void {
    var _this = this;
    _this.selectedStream = { id: "" } as any;
    _this.changesMadeLive = false;
    _this.changesMadeRecord = false;
    _this.chosenLiveStream = "";
    _this.chosenRecordStream = "";
    _this.liveStream = null;
    _this.recordStream = null;
    _this.liveOptions = {
      resolutions: [],
      bitrates: [],
      buffering: false,
      res: null,
      bitrate: null,
    };
    _this.recordOptions = {
      resolutions: [],
      bitrates: [],
      buffering: false,
      res: null,
      bitrate: null,
    };
  }
  uniqueReso(thisReso, resoArray) {
    for (var i = 0; i < resoArray.length; i++)
      if (
        thisReso.reference == resoArray[i].reference &&
        thisReso.display.split(" ")[0] == resoArray[i].display.split(" ")[0]
      )
        return false;
    return true;
  }
  uniqueBR(thisBR, brArray) {
    for (var i = 0; i < brArray.length; i++)
      if (thisBR.value == brArray[i].value) return false;
    return true;
  }

  connectionTimedOut() {
    if (moment().unix() - this.loadTime >= 3) return true;
    else return false;
  }
}
