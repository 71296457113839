<div
  bsModal
  #zctModal="bs-modal"
  class="modal fade show"
  tabindex="-1"
  role="dialog"
  [ngStyle]="style"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="imageUrl">
      <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
        <button type="button" class="close" (click)="cancelClicked()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="imageUrl" class="row">
          <div class="colSVG">
            <div class="row" style="padding-left: 20px;">
              <ng-container>
                <svg
                  viewbox="0 0 640 360"
                  width="640"
                  height="360"
                  style="border: 2px solid black;"
                >
                  <image
                    [attr.xlink:href]="imageUrl"
                    width="640"
                    height="360"
                  />
                </svg>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="okClicked()">
          Close
        </button>
      </div>
    </div>
    <div class="modal-content" *ngIf="markersList">
      <div class="modal-body">
        <button
          type="button"
          class="close float-left"
          (click)="cancelClicked()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <ng-container *ngFor="let name of markersList; index as i">
          <a routerLink="/video/viewer/{{ name }}" target="_blank">
            {{ name }}
          </a>
          <br *ngIf="i < markersList.length - 1" />
        </ng-container>
      </div>
    </div>
  </div>
</div>
