<div
  bsModal
  #messageModal="bs-modal"
  class="modal fade show"
  tabindex="-1"
  role="dialog"
  [ngStyle]="style"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ modalTitle }}</h4>
        <button type="button" class="close" (click)="cancelClicked()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div close="modal-body">
        <div class="row">
          <div class="col text-center">
            <h5 class="mt-1" *ngIf="!modalInput">{{ modalMessage }}</h5>
            <span *ngIf="modalInput"
              >{{ modalInputMessage
              }}<input class="my-2" type="string" [(ngModel)]="inText"
            /></span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="modalToConfirm && !modalInput"
          type="button"
          class="btn btn-primary"
          (click)="okClicked()"
        >
          Confirm
        </button>
        <button
          *ngIf="modalInput"
          type="button"
          class="btn btn-primary"
          (click)="confirmClicked()"
        >
          {{ modalInputButtonString }}
        </button>
        <button
          *ngIf="modalToConfirm"
          type="button"
          class="btn btn-secondary"
          (click)="cancelClicked()"
        >
          Cancel
        </button>
        <button
          *ngIf="!modalToConfirm"
          type="button"
          class="btn btn-secondary"
          (click)="cancelClicked()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
