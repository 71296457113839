import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { DatePipe, formatDate } from "@angular/common";
import { saveAs } from "file-saver/src/FileSaver";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
  selector: "app-log-viewer",
  templateUrl: "./log-viewer.component.html",
  styleUrls: ["./log-viewer.component.css"],
  providers: [DatePipe],
})
export class LogViewerComponent implements OnInit {
  rawData;
  logTable = [];
  users = [];
  actionNames = [];
  targets = [];
  usersList = [];
  usersSelected = [];
  actionsList = [];
  actionsSelected = [];
  targetsList = [];
  targetsSelected = [];
  dropdownSettings = {};
  headings = ["Time", "User", "Action", "Target", "Details"];
  cols = ["time", "userid", "action", "target", "details"];
  startDate;
  startDateString: string;
  endDate;
  endDateString: string;
  startTime;
  endTime;
  timeSlots = [];
  formatter = {};

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  ngOnInit(): void {
    for (var i = 0; i < 24; i++) {
      const t = ("00" + i).substr(-2) + ":00";
      this.timeSlots.push({
        value: t,
        text: t,
      });
    }
    this.startTime = this.endTime = this.timeSlots[0].value;
    this.dropdownSettings = {
      itemsShowLimit: 0,
      badgeShowLimit: 0,
      labelKey: "text",
      enableCheckAll: true,
    };
    this.getLog();
  }

  getLogs(start, end): void {
    var optionsString =
      "?startts=" +
      this.formatDateForURL(start) +
      "&endts=" +
      this.formatDateForURL(end) +
      "&order=ts.desc";
    this.http
      .get<Array<any>>("/db/rpc/log_condensed" + optionsString)
      .subscribe((data) => {
        this.users = [];
        this.actionNames = [];
        this.targets = [];

        this.rawData = data.map((x) => {
          x.show = true;
          x.time = formatDate(x.ts, "yyyy-MM-dd HH:mm:ss", "en-GB");
          x.target = x.cameraid ? x.cameraid : x.wallid;
          x.details = this.formatDetails(x); //JSON.stringify(x.detail);
          if (x.details.match(/null/)) x.details = "";
          if (x.action.match(/ISO/) || x.action.match(/EXPORT/)) {
            x.target = "File System";
          }

          this.users.push(x.userid);
          this.actionNames.push(x.action);
          this.targets.push(x.target);
          return x;
        });
        this.users = this.users.reduce(this.reducer, []).sort(this.sorting);
        this.actionNames = this.actionNames
          .reduce(this.reducer, [])
          .sort(this.sorting);
        this.targets = this.targets.reduce(this.reducer, []).sort(this.sorting);
        this.usersList = this.users.map((x, i) => {
          return { id: i, text: x };
        });
        this.actionsList = this.actionNames.map((x, i) => {
          return { id: i, text: x };
        });
        this.targetsList = this.targets.map((x, i) => {
          return { id: i, text: x };
        });
        this.logTable = this.rawData;
      });
  }

  getLog() {
    var startDate, endDate;
    if (this.startDate) {
      this.startDateString = this.datePipe.transform(
        this.startDate,
        "yyyy-MM-dd"
      );
      startDate = new Date(`${this.startDateString}T${this.startTime}`);
    } else {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
    }
    if (this.endDate) {
      this.endDateString = this.datePipe.transform(this.endDate, "yyyy-MM-dd");
      endDate = new Date(`${this.endDateString}T${this.endTime}`);
    } else {
      endDate = new Date();
    }
    this.getLogs(startDate, endDate);
  }

  onItemSelect(e) {
    this.updateLogTable();
  }
  onSelectAll(e) {
    this.updateLogTable();
  }
  reducer(acc, curr) {
    if (curr == null) return acc;
    else if (acc.includes(curr)) return acc;
    else return [...acc, curr];
  }
  sorting(a, b) {
    if (a > b) return 1;
    else if (a < b) return -1;
    return 0;
  }

  updateLogTable(): void {
    this.logTable.forEach((x) => {
      var validUser = false;
      var validAction = false;
      var validTarget = false;
      if (0 == this.usersSelected.length) validUser = true;
      for (var i = 0; i < this.usersSelected.length; i++) {
        if (x.userid && x.userid.match(this.usersSelected[i].text)) {
          validUser = true;
          break;
        }
      }
      if (validUser) {
        if (0 == this.actionsSelected.length) validAction = true;
        for (var i = 0; i < this.actionsSelected.length; i++) {
          if (x.action && x.action.match(this.actionsSelected[i].text)) {
            validAction = true;
            break;
          }
        }
        if (validAction) {
          if (0 == this.targetsSelected.length) validTarget = true;
          for (var i = 0; i < this.targetsSelected.length; i++) {
            if (x.target && x.target.match(this.targetsSelected[i].text)) {
              validTarget = true;
              break;
            }
          }
        }
      }
      x.show = validUser && validAction && validTarget;
    });
  }

  exportLogs(): void {
    const blob = new Blob([this.genCsv()], { type: "text/csv" });
    saveAs(blob, this.genFilename());
  }
  genFilename(): string {
    let nowDate = new Date();
    let y = nowDate.getUTCFullYear();
    let m = ("00" + (nowDate.getUTCMonth() + 1)).substr(-2);
    let d = ("00" + nowDate.getUTCDate()).substr(-2);
    let h = ("00" + nowDate.getUTCHours()).substr(-2);
    let n = ("00" + nowDate.getUTCMinutes()).substr(-2);
    let s = ("00" + nowDate.getUTCSeconds()).substr(-2);

    return `Logs_${y}-${m}-${d}_${h}${n}${s}.csv`;
  }
  genCsv(): string {
    let cols = [];
    let headings = [];
    for (let i = 0; i < this.headings.length; i++) {
      headings.push(this.headings[i]);
      cols.push(this.cols[i]);
    }
    const out = [];
    out.push(headings.join(","));
    for (let i = 0; i < this.logTable.length; i++) {
      if (this.logTable[i].show)
        out.push(
          [
            cols.map((c) => {
              if (this.logTable[i][c] && this.logTable[i][c].match(","))
                return '"' + this.logTable[i][c].replace(/"/g, "'") + '"';
              else return this.logTable[i][c];
            }),
          ].join(",")
        );
    }
    return out.join("\n");
  }

  formatDateForURL(date): string {
    let y = date.getUTCFullYear();
    let m = ("00" + (date.getUTCMonth() + 1)).substr(-2);
    let d = ("00" + date.getUTCDate()).substr(-2);
    let h = ("00" + date.getUTCHours()).substr(-2);
    let n = ("00" + date.getUTCMinutes()).substr(-2);
    let s = ("00" + date.getUTCSeconds()).substr(-2);

    return `${y}-${m}-${d}T${h}:${n}:${s}Z`;
  }

  formatDetails(x) {
    if (x.action.match(/PTZCONTROL/)) {
      const et = new Date(x.detail.endts).getTime();
      const ts = new Date(x.ts).getTime();
      const duration = Math.floor((et - ts) / 1000.0) + 1;
      return `Continuous PTZ, ${x.detail.events} movements, over ${duration} secs`;
    } else if (x.action.match(/PATROLCONTROL/)) {
      var temp = `Patrol ${x.detail.settings.enabled ? "en" : "dis"}abled`;
      if (x.detail.settings.enabled && x.detail.settings.steps) {
        temp += `, ${x.detail.settings.steps.length} steps (${
          x.detail.settings.steps.filter((y) => y.snapshot).length
        } with snapshot)`;
      }
      return temp;
    } else if (x.action.match(/ISODOWNLOAD/)) {
      return `Downloaded ${x.detail.name}_${x.detail.subfile}.iso`;
    } else if (x.action.match(/ISOBURN/)) {
      return `Requested ISO files ${x.detail.name}: ${x.detail.playlist
        .map(
          (p) =>
            p.camera +
            " from " +
            formatDate(p.stime, "yyyy-MM-dd HH:mm", "en-GB") +
            " to " +
            formatDate(p.etime, "yyyy-MM-dd HH:mm", "en-GB")
        )
        .join(", ")}`;
    } else if (x.action.match(/PLAYBACKVIEW/)) {
      const et = new Date(x.detail.endts).getTime();
      const ts = new Date(x.ts).getTime();
      const duration = Math.floor((et - ts) / 60000.0) + 1;
      return `Viewed recordings from ${formatDate(
        x.detail.start_time,
        "yyyy-MM-dd HH:mm",
        "en-GB"
      )} to ${formatDate(
        x.detail.end_time,
        "yyyy-MM-dd HH:mm",
        "en-GB"
      )} for ${duration} min(s)`;
    } else if (x.action.match(/PTZPRESET/)) {
      return `Activated Preset ${x.detail.value}`;
    } else if (x.action.match(/LOGVIEW/)) {
      return `Viewed log history from ${formatDate(
        x.detail.start_time,
        "yyyy-MM-dd HH:mm",
        "en-GB"
      )} to ${formatDate(x.detail.end_time, "yyyy-MM-dd HH:mm", "en-GB")}`;
    }
    return "";
  }
}
