<div class="animated fadeIn">
  <div class="row">
    <div [ngClass]="showCamList ? 'col-md-8' : 'col-md-12'">
      <app-mapviewer
        *ngIf="cameras"
        [setLat]="setLat"
        [setLong]="setLong"
        [setZoom]="setZoom"
        [fitInitiallyOnly]="true"
        [markers]="filteredMarkers"
        [openInNewWindow]="viewInNewWindow"
        title="Cameras"
        (markerClick)="markerClicked($event)"
        (mapIsReady)="mapReady($event)"
        (viewChanged)="viewChanged($event)"
      ></app-mapviewer>
      <!-- <button type="button" class="btn btn-primary" (click)="showAll()">
        Show All
      </button> -->
    </div>
    <div *ngIf="showCamList" class="col-md-4">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Cameras
          <form class="form float-right">
            <input
              size="40"
              class="form-control"
              #searchBox
              placeholder="{{ searchPlaceholder }}"
              id="search-box"
              (input)="search(searchBox.value)"
            />
          </form>
        </div>
        <div class="card-body">
          <table class="table">
            <tbody>
              <tr
                *ngFor="let cam of filteredCams"
                (click)="zoomTo(cam)"
                tooltip="Click to show"
                [delay]="1000"
              >
                <td *ngIf="indexCams">{{ cam.index }}</td>
                <td>{{ cam.name }} - {{ cam.location }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-info-popup #iDialog></app-info-popup>
