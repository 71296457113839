<div class="animated fadeIn">
  <div class="row d-flex flex-row">
    <div class="col-md-12 align-self-start">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> File Selection
          <div class="float-right">
            <button
              class="btn btn-danger"
              type="button"
              (click)="reset()"
              tooltip="Clear Selections"
              [delay]="500"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
        <div class="card-body padding-0 for-overlaying">
          <table class="table">
            <tbody>
              <tr class="dateDropScale">
                <td class="headercell">Date:</td>
                <td class="dateCell">
                  <input
                    class="form-control"
                    placeholder="Select date"
                    type="string"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    #dp="bsDatepicker"
                    bsDatepicker
                    [(ngModel)]="startDate"
                    [bsConfig]="{
                      dateInputFormat: 'YYYY-MM-DD',
                      containerClass: 'theme-default'
                    }"
                  />
                </td>
                <td class="headercell">Cameras:</td>
                <td>
                  <ss-multiselect-dropdown
                    #camSelect
                    *ngIf="cameraOptions.length > 0"
                    style="width: 100%;"
                    [settings]="dropdownSettings"
                    [options]="cameraOptions"
                    [(ngModel)]="camerasSelected"
                    tooltip="List of cameras to copy to FTP server"
                    [delay]="500"
                  ></ss-multiselect-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="float-right">
            <button class="btn btn-primary" type="button" (click)="submit()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-flash-message #fmDialog></app-flash-message>
