import { AppConfig } from "../app.config";

export enum ScreenMode {
  OneByOne = 1,
  TwoByTwo = 2,
  ThreeByThree = 3,
  FourByThree = 4,
  None = 0,
}

export class CameraData {
  cameras: Camera[];

  getCamera(cameraId): Camera {
    var cameras = this.cameras.filter((x) => x.id == cameraId);
    if (cameras.length > 0) {
      return cameras[0];
    } else return null;
  }

  cameraExists(cameraId): boolean {
    return this.getCamera(cameraId) != null;
  }

  cameraName(cameraId) {
    var camera = this.getCamera(cameraId);
    if (camera) {
      return camera.id + " - " + camera.location;
    } else return null;
  }
}

export class Camera {
  id: string;
  location: string;
  host: string;
  rtspport: string;
  longitude?: number;
  latitude?: number;
}

export class PanTiltZoom {
  id: string;
  description: string;
}

export class WallList {
  description?: string;
  walls: string[];
}

export class WallConfig {
  description: string;
  screens: WallScreen[];
  disabledWalls?: number[];
}

export class Sequence {
  stream: string;
  interval: number;
}

export class WallScreen {
  y: number;
  x: number;
  layout: string;
  sequences: Sequence[][];
}

export class Patrol {
  enabled: boolean = true;
  snapshotdelay: number = 10;
  steps: Preset[];
}

export class Preset {
  preset: string;
  time: number = 0;
  snapshot: boolean = false;
}

export class Permission {
  superuser: boolean = false;
  cameras: string[];
  walls: string[];
  permissions: string[];
}

export class User {
  id: string;
  config: Permission;
}

export class Stream {
  id: string;
  bitrate?: string;
  fps?: string;
  keyframerate?: string;
}

export class Settings {
  streamServerUrl: string = AppConfig.settings.streamServerUrl;
  cameraListUrl: string = AppConfig.settings.cameraListUrl;
  streamListUrl: string = AppConfig.settings.streamListUrl;
  streamUrlStart: string = AppConfig.settings.streamUrlStart;
  streamUrlEnd: string = AppConfig.settings.streamUrlEnd;
  ptzUrlStart: string = AppConfig.settings.ptzUrlStart;
  ptzUrlEnd: string = AppConfig.settings.ptzUrlEnd;
  ptzPatrolUrl: string = AppConfig.settings.ptzPatrolUrl;
  playbackServerUrl: string = AppConfig.settings.playbackServerUrl;
  playbackPreviewUrl: string = AppConfig.settings.playbackPreviewUrl;
  playbackExportUrl: string = AppConfig.settings.playbackExportUrl;
  playbackIsoExportUrl: string = AppConfig.settings.playbackIsoExportUrl;
  rtspUrlStart: string = AppConfig.settings.rtspUrlStart;
  rtspUrlEnd: string = AppConfig.settings.rtspUrlEnd;
  videoWallUrl: string = AppConfig.settings.videoWallUrl;
  permissionsUrl: string = AppConfig.settings.permissionsUrl;
  playbackIsoPackageUrl: string = AppConfig.settings.playbackIsoPackageUrl;
  usersUrl: string = AppConfig.settings.usersUrl;
  userIdUrl: string = AppConfig.settings.userIdUrl;

  getPtzUrl(cameraId: string) {
    return this.streamServerUrl + this.ptzUrlStart + cameraId + this.ptzUrlEnd;
  }

  getStreamUrl(cameraId: string) {
    return (
      this.streamServerUrl + this.streamUrlStart + cameraId + this.streamUrlEnd
    );
  }

  getPtzRequestUrl(cameraId: string) {
    return this.streamServerUrl + this.ptzUrlStart + cameraId + this.ptzUrlEnd;
  }

  getPlaybackPreviewUrl(cameraId: string, startDate: string, endDate: string) {
    return (
      this.playbackServerUrl +
      this.playbackPreviewUrl +
      cameraId +
      "/" +
      startDate +
      "/" +
      endDate
    );
  }

  getPlaybackExportUrl(cameraId: string, startDate: string, endDate: string) {
    return (
      this.playbackServerUrl +
      this.playbackExportUrl +
      cameraId +
      "/" +
      startDate +
      "/" +
      endDate
    );
  }

  getPlaybackIsoExportUrl(
    cameraId: string,
    startDate: string,
    endDate: string
  ) {
    return (
      this.playbackServerUrl +
      this.playbackIsoExportUrl +
      cameraId +
      "/" +
      startDate +
      "/" +
      endDate
    );
  }

  getPlaybackIsoPackageUrl() {
    return this.playbackServerUrl + this.playbackIsoPackageUrl;
  }

  getwsUrl() {
    if (window.location.protocol === "https:")
      return "wss://" + window.location.host + "/ws/";
    else return "ws://" + window.location.host + "/ws/";
  }
  getrtspUrl(port: string) {
    return this.rtspUrlStart + port + this.rtspUrlEnd;
  }

  getPatrolUrl(cameraId: string) {
    return this.getPtzUrl(cameraId) + this.ptzPatrolUrl;
  }
}
