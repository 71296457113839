import { Component, OnInit, EventEmitter, Inject, Input } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FlashMessageService } from "./flash-message.service";

@Component({
  selector: "app-flash-message",
  templateUrl: "./flash-message.component.html"
  //,  styleUrls: ["./flash-message.component.css"]
})
export class FlashMessageComponent {
  onConfirm: EventEmitter<any> = new EventEmitter();
  onCancel: EventEmitter<any> = new EventEmitter();
  confirmInput: EventEmitter<any> = new EventEmitter();
  private backdrop: HTMLElement;
  style: any;
  inText: string;

  @Input() modalToConfirm: boolean;
  @Input() modalTitle: string;
  @Input() modalMessage: string;
  @Input() modalInput: boolean;
  @Input() modalInputMessage: string;
  @Input() modalInputButtonString: string;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setModalTitle(t: string) {
    this.modalTitle = t;
  }
  setModalMessage(t: string) {
    this.modalMessage = t;
  }
  setModalToConfirm(t: boolean) {
    this.modalToConfirm = t;
  }
  setModal(t: string, m: string, c: boolean) {
    this.modalTitle = t;
    this.modalMessage = m;
    this.modalToConfirm = c;
  }
  setModalInputFlag(t: boolean) {
    this.modalInput = t;
  }
  setModalInputMessage(t: string) {
    this.modalInputMessage = t;
  }
  setModalInputButtonString(t: string) {
    this.modalInputButtonString = t;
  }
  setModalInput(t: boolean, m: string, b: string) {
    this.modalInput = t;
    this.modalInputMessage = m;
    this.modalInputButtonString = b;
  }
  resetModal() {
    this.modalTitle = "";
    this.modalMessage = "";
    this.modalToConfirm = false;
    this.modalInput = false;
    this.modalInputMessage = "";
    this.modalInputButtonString = "";
    this.inText = null;
  }

  okClicked() {
    this.onConfirm.emit();
  }

  cancelClicked() {
    this.onCancel.emit();
  }

  confirmClicked() {
    this.confirmInput.emit({ text: this.inText });
  }

  show() {
    this.document.body.classList.add("modal-open");
    this.style = { display: "block" };
    this.showBackdrop();
  }

  hide() {
    this.document.body.classList.remove("modal-open");
    this.style = { display: "none" };
    this.hideBackdrop();
  }

  showBackdrop() {
    this.backdrop = this.document.createElement("div");
    this.backdrop.classList.add("modal-backdrop");
    this.backdrop.classList.add("show");
    this.document.body.appendChild(this.backdrop);
  }

  hideBackdrop() {
    this.backdrop.remove();
  }
}
