<link
  rel="stylesheet"
  href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css"
/>
<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Logs
          <button
            class="float-right btn btn-primary"
            (click)="exportLogs()"
            *ngIf="logTable.length > 0"
          >
            Export To CSV
          </button>
        </div>
        <div class="card-body">
          <table class="table">
            <tbody>
              <tr>
                <td>Start</td>
                <td>
                  <div class="row">
                    <div class="col">
                      <input
                        class="form-control"
                        placeholder="Select date"
                        type="string"
                        #dp="bsDatepicker"
                        bsDatepicker
                        [(ngModel)]="startDate"
                        [bsConfig]="{
                          dateInputFormat: 'YYYY-MM-DD',
                          containerClass: 'theme-default'
                        }"
                      />
                    </div>
                    <div class="col">
                      <select class="form-control" [(ngModel)]="startTime">
                        <option
                          *ngFor="let slot of timeSlots"
                          value="{{ slot.value }}"
                          >{{ slot.text }}</option
                        >
                      </select>
                    </div>
                  </div>
                </td>
                <td>End</td>
                <td>
                  <div class="row">
                    <div class="col">
                      <input
                        class="form-control"
                        placeholder="Select date"
                        type="string"
                        #dp="bsDatepicker"
                        bsDatepicker
                        [(ngModel)]="endDate"
                        [bsConfig]="{
                          dateInputFormat: 'YYYY-MM-DD',
                          containerClass: 'theme-default'
                        }"
                      />
                    </div>
                    <div class="col">
                      <select class="form-control" [(ngModel)]="endTime">
                        <option
                          *ngFor="let slot of timeSlots"
                          value="{{ slot.value }}"
                          >{{ slot.text }}</option
                        >
                      </select>
                    </div>
                  </div>
                </td>
                <td>
                  <button class="btn btn-primary" (click)="getLog()">
                    Get Logs
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table fullwidth fixedwidth">
            <thead class="fullwidth">
              <tr class="fullwidth">
                <th class="time"></th>
                <th class="userid">
                  <angular2-multiselect
                    [settings]="dropdownSettings"
                    [data]="usersList"
                    [(ngModel)]="usersSelected"
                    (onClose)="updateLogTable()"
                    (onFilterDeSelectAll)="updateLogTable()"
                    (onGroupDeSelect)="updateLogTable()"
                    (onDeSelect)="updateLogTable()"
                    (onDeSelectAll)="updateLogTable()"
                  >
                  </angular2-multiselect>
                </th>
                <th class="action">
                  <angular2-multiselect
                    [settings]="dropdownSettings"
                    [data]="actionsList"
                    [(ngModel)]="actionsSelected"
                    (onClose)="updateLogTable()"
                    (onFilterDeSelectAll)="updateLogTable()"
                    (onGroupDeSelect)="updateLogTable()"
                    (onDeSelect)="updateLogTable()"
                    (onDeSelectAll)="updateLogTable()"
                  >
                  </angular2-multiselect>
                </th>
                <th class="target">
                  <angular2-multiselect
                    [settings]="dropdownSettings"
                    [data]="targetsList"
                    [(ngModel)]="targetsSelected"
                    (onClose)="updateLogTable()"
                    (onFilterDeSelectAll)="updateLogTable()"
                    (onGroupDeSelect)="updateLogTable()"
                    (onDeSelect)="updateLogTable()"
                    (onDeSelectAll)="updateLogTable()"
                  >
                  </angular2-multiselect>
                </th>

                <th class="details"></th>
              </tr>

              <tr class="fullwidth">
                <th *ngFor="let col of headings">{{ col }}</th>
              </tr>
            </thead>
            <tbody class="fullwidth">
              <tr
                class="fullwidth"
                *ngFor="let log of logTable"
                [ngClass]="{ hide: !log.show }"
              >
                <!-- <td class="limited" *ngFor="let col of cols">
                  {{ log[col] }}
                </td> -->
                <td class="time">
                  {{ log["time"] }}
                </td>
                <td class="userid">
                  {{ log["userid"] }}
                </td>
                <td class="action">
                  {{ log["action"] }}
                </td>
                <td class="target">
                  {{ log["target"] }}
                </td>
                <td class="details">
                  {{ log["details"] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
