<div class="animated fadeIn" *ngIf="!streamData && connectionTimedOut()">
  <p class="text-center h1 my-auto">
    <i class="fa fa-server fa-3x"></i><br />Remote Server Error
  </p>
</div>
<div class="animated fadeIn" *ngIf="streamData || !connectionTimedOut()">
  <div class="row d-flex flex-row">
    <div class="col-md-9 align-self-start">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Stream Settings
          <div class="float-right">
            <button
              *ngIf="selectedStream"
              class="btn btn-primary"
              type="button"
              (click)="reload()"
              tooltip="Revert Changes"
              [delay]="500"
              [disabled]="!changesMadeLive && !changesMadeRecord"
            >
              <i class="fa fa-refresh"></i>
            </button>
            <button
              class="btn btn-danger"
              type="button"
              (click)="cancelChanges()"
              tooltip="Clear Camera"
              [delay]="500"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
        <div class="card-body padding-0 for-overlaying">
          <table class="blankpage" *ngIf="selectedStream.id.length == 0">
            <tbody>
              <tr>
                <td class="message">
                  <h2>Select A Camera From The List On The Right</h2>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="table for-use"
            [style.opacity]="selectedStream.id.length > 0 ? 1 : 0"
          >
            <tbody>
              <tr>
                <td class="headercell">Camera Stream:</td>
                <td>
                  <input
                    type="string"
                    name="userID"
                    [(ngModel)]="selectedStream.id"
                    [disabled]="true"
                  />
                </td>
              </tr>
              <tr>
                <td class="headercell">Settings:</td>
                <td>
                  <table class="noBorders">
                    <tr>
                      <td>Stream</td>
                      <td>Resolution</td>
                      <td>Bitrate</td>
                      <td>Use Buffering</td>
                    </tr>
                    <tr>
                      <td class="valigned">Live Stream</td>
                      <td>
                        <select
                          class="form-control"
                          name="nodeType"
                          class="form-control"
                          id="liveResSelect"
                          [(ngModel)]="liveOptions.res"
                          [disabled]="liveOptions.resolutions.length <= 1"
                          (change)="changeLiveProfile()"
                        >
                          <option
                            *ngFor="let res of liveOptions.resolutions"
                            value="{{ res.reference }}"
                            >{{ res.display }}</option
                          >
                        </select>
                      </td>
                      <td>
                        <select
                          class="form-control"
                          name="nodeType"
                          class="form-control"
                          id="liveBitRateSelect"
                          [(ngModel)]="liveOptions.bitrate"
                          [disabled]="liveOptions.bitrates.length <= 1"
                          (change)="changeLiveProfile()"
                        >
                          <option
                            *ngFor="let br of liveOptions.bitrates"
                            value="{{ br.value }}"
                            >{{ br.display }}</option
                          >
                        </select>
                      </td>
                      <td class="valigned">
                        <mat-checkbox
                          class="valignedPlus"
                          [(ngModel)]="liveOptions.buffering"
                          (change)="changeLiveProfile()"
                        ></mat-checkbox>
                      </td>
                    </tr>
                    <tr *ngIf="showRecordOptions">
                      <td class="valigned">Recording Stream</td>
                      <td>
                        <select
                          class="form-control"
                          name="nodeType"
                          class="form-control"
                          id="recordResSelect"
                          [(ngModel)]="recordOptions.res"
                          [disabled]="recordOptions.resolutions.length <= 1"
                          (change)="changeRecordProfile()"
                        >
                          <option
                            *ngFor="let res of recordOptions.resolutions"
                            value="{{ res.reference }}"
                            >{{ res.display }}</option
                          >
                        </select>
                      </td>
                      <td>
                        <select
                          class="form-control"
                          name="nodeType"
                          class="form-control"
                          id="recordBitRateSelect"
                          [(ngModel)]="recordOptions.bitrate"
                          [disabled]="recordOptions.bitrates.length <= 1"
                          (change)="changeRecordProfile()"
                        >
                          <option
                            *ngFor="let br of recordOptions.bitrates"
                            value="{{ br.value }}"
                            >{{ br.display }}</option
                          >
                        </select>
                      </td>
                      <td class="valigned">
                        <!--<mat-checkbox
                          class="valignedPlus"
                          [(ngModel)]="recordOptions.buffering"
                          (change)="changeRecordProfile()"
                        ></mat-checkbox> -->
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="float-right">
            <button
              class="btn btn-primary"
              type="button"
              (click)="commitChanges()"
              [disabled]="!changesMadeLive && !changesMadeRecord"
            >
              Submit Changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card-header">
        <div class="input-group">
          <input
            class="form-control"
            #searchBox
            placeholder="Search"
            id="search-box"
            (input)="search(searchBox.value)"
          />
          <span class="input-group-append">
            <button
              class="btn btn-primary"
              type="button"
              (click)="searchBox.value = ''"
            >
              Clear
            </button>
          </span>
        </div>
      </div>
      <div
        class="card-body scroll overflow-auto w-100"
        style="padding: 0px; height: 80vh;"
      >
        <ul class="list-group w-100">
          <li
            class="list-group-item"
            *ngFor="let camera of streamData"
            (dblclick)="doubleClick(camera)"
            tooltip="Double click to select"
            [delay]="1000"
          >
            {{ camera.id }} - {{ camera.location }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-flash-message #fmDialog></app-flash-message>
