import { Component } from "@angular/core";
import { navItems } from "../../_nav";
import { VideoService } from "../../video/video.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  public sidebarMinimized = true;
  public baseNavItems = navItems;
  public navItems;

  constructor(private videoService: VideoService) {
    this.setMenu();
  }

  setMenu() {
    this.videoService.getPermissionsList().subscribe((p) => {
      var temp = this.baseNavItems.filter(
        (x) =>
          !x.permission ||
          p.superuser ||
          x.permission.split(",").some((x) => p.permissions.includes(x))
      );
      this.videoService.getWallList().subscribe(
        (w) => {
          var userWalls = w.filter(
            (x) =>
              p.superuser ||
              (p.walls && p.walls.includes(x)) ||
              p.permissions.includes("allwalls")
          );
          for (var navI of temp) {
            if (navI.name.match("Video Wall"))
              if (userWalls.length > 1) {
                navI.children = [];
                for (var n of userWalls) {
                  navI.children.push({
                    name: n,
                    url: navI.url + "/" + n,
                    icon: "fa fa-long-arrow-right",
                  });
                }
                delete navI.url;
              } else {
                navI.url += "/" + userWalls[0];
              }
          }
          this.navItems = temp;
        },
        (err) => {
          this.navItems = temp;
        }
      );
    });
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
