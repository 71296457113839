import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Location, DatePipe, formatDate } from "@angular/common";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
  HttpRequest,
} from "@angular/common/http";
import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import { Camera, Settings, Permission, User } from "../video/video";
import * as moment from "moment";
import { VideoService } from "../video/video.service";
import { FlashMessageComponent } from "../flash-message/flash-message.component";
import { FlashMessageService } from "../flash-message/flash-message.service";

@Component({
  selector: "app-ftp-server-access",
  templateUrl: "./ftp-server-access.component.html",
  styleUrls: ["./ftp-server-access.component.css"],
  providers: [DatePipe],
})
export class FtpServerAccessComponent implements OnInit {
  selectedCameras = [];
  dropdownSettings = {};
  camerasSelected = [];
  cameraOptions: IMultiSelectOption[] = [];
  minDate;
  maxDate;
  startDate;
  startDateString: string;

  @ViewChild("camSelect", { static: false }) public camSelect;
  @ViewChild("fmDialog", { static: true })
  public fmDialog: FlashMessageComponent;

  constructor(
    private http: HttpClient,
    private flashService: FlashMessageService,
    private videoService: VideoService,
    private datePipe: DatePipe
  ) {
    this.minDate = new Date(new Date().getTime() - 28 * 8.64e7);
    this.maxDate = new Date(new Date().getTime() - 8.64e7);
  }

  ngOnInit(): void {
    var _this = this;
    this.flashService.register(this.fmDialog);
    this.dropdownSettings = {
      itemsShowLimit: 0,
      badgeShowLimit: 0,
      labelKey: "name",
      enableCheckAll: true,
      showCheckAll: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 3,
      buttonClasses: "form-control",
      containerClasses: "ng-pristine",
      displayAllSelectedText: true,
    };
    this.videoService.getVideoList().subscribe((cams) => {
      var camOptions = [];
      cams.forEach((x) => {
        camOptions.push({
          id: x.id,
          name: x.id + " " + x.location,
        });
      });
      this.cameraOptions = camOptions;
    });
  }

  copyFiles() {
    this.startDateString = this.datePipe.transform(
      this.startDate,
      "yyyy-MM-dd"
    );
    var data = {
      cameras: this.camerasSelected,
      date: this.startDateString,
    };
    var url = "ftp-server/copy";
    this.http.post<any>(url, data).subscribe(
      (x) => {
        this.resetDates();
      },
      (err) => {
        this.flashService
          .showThis(
            "Error Requesting File Transfer",
            err.statusText + " : " + err.error.details,
            false
          )
          .then((res) => {
            this.messageModalReset();
          })
          .catch((err) => {
            this.messageModalReset();
          });
      }
    );
    // Do http post to a server to respond by running a script that copies to FTP server.
  }

  reset() {
    this.resetDates();
    this.resetCameras();
  }
  resetCameras() {
    this.camerasSelected = [];
  }
  resetDates() {
    this.startDate = null;
    this.startDateString = null;
  }

  submit() {
    this.copyFiles();
  }

  messageModalReset(): void {
    this.flashService.resetModal();
  }
}
