import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
  HttpRequest,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { SAVER, Saver } from "./saver.provider";
import {
  distinctUntilChanged,
  scan,
  map,
  tap,
  publishReplay,
  refCount,
} from "rxjs/operators";
import {
  Camera,
  PanTiltZoom,
  Settings,
  WallConfig,
  Preset,
  WallScreen,
  Patrol,
  Permission,
  WallList,
  User,
  Stream,
} from "./video";

const httpOptions: any = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  responseType: "text",
};

export interface Download {
  state: "PENDING" | "IN_PROGRESS" | "DONE";
  progress: number;
  content: Blob | null;
}

@Injectable({
  providedIn: "root",
})
export class VideoService {
  Settings = new Settings();
  permissions: Observable<Permission>;
  userId: Observable<string>;
  permissionsCacheClearTimer;
  userIdCacheClearTimer;
  cacheClearTimeout = 30000;
  wallName = "";

  constructor(private http: HttpClient, @Inject(SAVER) private save: Saver) {}

  getUserId(): Observable<any> {
    return this.http.get<any>(this.Settings.userIdUrl);
  }

  getPermissionsList(): Observable<Permission> {
    if (window.location.host.match("localhost")) {
      this.permissions = of({
        superuser: true,
        cameras: [],
        walls: [],
        permissions: [],
      });
    } else if (!this.permissions) {
      this.permissions = this.http.get(this.Settings.permissionsUrl).pipe(
        map(function (r) {
          var permissions = Object.assign({}, r) as Permission;
          if (permissions.walls && permissions.walls.length > 0) {
            permissions.permissions.push("walls");
          }
          this.lastPermissionCall = Date.now();
          return permissions;
        }),
        publishReplay(1),
        refCount()
      );
    }
    window.clearTimeout(this.permissionsCacheClearTimer);
    this.permissionsCacheClearTimer = window.setTimeout(() => {
      this.clearCachedPermissions();
    }, this.cacheClearTimeout);
    return this.permissions;
  }

  getUsersList(): Observable<User[]> {
    return this.http.get<User[]>(this.Settings.usersUrl);
  }

  clearCachedPermissions() {
    this.permissions = null;
  }
  clearCachedUserId() {
    this.userId = null;
  }

  getVideoList(): Observable<Camera[]> {
    return this.http.get<Camera[]>(
      this.Settings.streamServerUrl + this.Settings.cameraListUrl
    );
  }

  getStreamList(): Observable<Stream[]> {
    return this.http.get<Stream[]>(
      this.Settings.streamServerUrl + this.Settings.streamListUrl
    );
  }

  getPtzList(cameraId: string): Observable<any> {
    return this.http.get(this.Settings.getPtzUrl(cameraId));
  }

  sendPtzRequest(CameraId: string, ptzId: string): Observable<any> {
    var url = this.Settings.getPtzRequestUrl(CameraId);
    return this.http.put<any>(url, { preset: ptzId }, httpOptions);
  }

  sendPtzSetting(CameraId: string, numIn: number, nameIn: string) {
    var url = this.Settings.getPtzRequestUrl(CameraId);
    var data = { setpreset: true, num: numIn, name: nameIn };
    return this.http.put<any>(url, data);
  }

  sendContinuousPtzRequest(CameraId: string, axes: number[]) {
    var url = this.Settings.getPtzRequestUrl(CameraId);
    return this.http.put<any>(url, { continuous: axes });
  }

  sendIrisRequest(CameraId: string, value: number) {
    var url = this.Settings.getPtzRequestUrl(CameraId);
    return this.http.put<any>(url, { iris: value });
  }

  sendFocusRequest(CameraId: string, value: number) {
    var url = this.Settings.getPtzRequestUrl(CameraId);
    return this.http.put<any>(url, { focus: value });
  }

  sendWiperRequest(CameraId: string, value: boolean) {
    var url = this.Settings.getPtzRequestUrl(CameraId);
    return this.http.put<any>(url, { wiper: value });
  }

  exportRecording(
    CameraId: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    var url = this.Settings.getPlaybackExportUrl(CameraId, startDate, endDate);
    return this.http.get(url, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  exportRecordingIso(
    CameraId: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    var url = this.Settings.getPlaybackIsoExportUrl(
      CameraId,
      startDate,
      endDate
    );
    return this.http.get(url, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  exportPackage(data): Observable<any> {
    var url = this.Settings.getPlaybackIsoPackageUrl();
    return this.http.post(url, data);
  }

  checkExport(workRef: string): Observable<any> {
    var url = this.Settings.playbackServerUrl + "queue/" + workRef;
    return this.http.get(url, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  downloadExport(workRef: string, filename: string): Observable<Download> {
    var url = this.Settings.playbackServerUrl + "complete/" + workRef;
    return this.http
      .get(url, {
        responseType: "blob",
        observe: "events",
        reportProgress: true,
      })
      .pipe(download((blob) => this.save(blob, filename)));
  }
  blobExport(workRef: string): Observable<Blob> {
    var url = this.Settings.playbackServerUrl + "complete/" + workRef;
    return this.http.get(url, {
      responseType: "blob",
    });
  }
  dlURL(workRef: string): string {
    return this.Settings.playbackServerUrl + "complete/" + workRef;
  }
  packageURL(workRef: string, i: number): string {
    return this.Settings.playbackServerUrl + "complete/" + workRef + "/" + i;
  }

  confirmExport(location: string): Observable<any> {
    var url = this.Settings.playbackServerUrl + location;
    return this.http.get(url);
  }

  getWallList(): Observable<string[]> {
    var url = this.Settings.videoWallUrl + "walls";
    return this.http.get<string[]>(url);
  }

  setWallName(name: string): void {
    if (name) this.wallName = name;
  }
  getWallConfig(name: string): Observable<WallConfig> {
    if (name) this.wallName = name;
    var url = this.Settings.videoWallUrl + "wall/" + this.wallName;
    return this.http.get<WallConfig>(url);
  }

  saveWallConfig(screen: WallScreen): Observable<any> {
    var url = this.Settings.videoWallUrl + "wall/" + this.wallName;
    return this.http.put(url, JSON.stringify([screen]), httpOptions);
  }

  getPatrol(cameraId: string): Observable<Patrol> {
    var url = this.Settings.getPatrolUrl(cameraId);
    return this.http.get<Patrol>(url);
  }

  updatePatrol(cameraId: string, patrol: Patrol): Observable<any> {
    var url = this.Settings.getPatrolUrl(cameraId);
    return this.http.put(url, JSON.stringify(patrol), httpOptions);
  }
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

export function download(
  saver?: (b: Blob) => void
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan(
        (previous: Download, event: HttpEvent<Blob>): Download => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : previous.progress,
              state: "IN_PROGRESS",
              content: null,
            };
          }
          if (isHttpResponse(event)) {
            if (saver && event.body) {
              saver(event.body);
            }
            return {
              progress: 100,
              state: "DONE",
              content: event.body,
            };
          }
          return previous;
        },
        { state: "PENDING", progress: 0, content: null }
      )
    );
}
