import { Injectable } from "@angular/core";
import { FlashMessageComponent } from "./flash-message.component";

@Injectable({
  providedIn: "root"
})
export class FlashMessageService {
  registeredDialog: FlashMessageComponent;

  constructor() {}

  register(dialog: FlashMessageComponent) {
    this.registeredDialog = dialog;
  }

  setModalTitle(t: string) {
    this.registeredDialog.setModalTitle(t);
  }
  setModalMessage(t: string) {
    this.registeredDialog.setModalMessage(t);
  }
  setModalToConfirm(t: boolean) {
    this.registeredDialog.setModalToConfirm(t);
  }
  setModal(t: string, m: string, c: boolean) {
    this.registeredDialog.setModal(t, m, c);
  }
  setModalInputFlag(t: boolean) {
    this.registeredDialog.setModalInputFlag(t);
  }
  setModalInputMessage(t: string) {
    this.registeredDialog.setModalInputMessage(t);
  }
  setModalInputButtonString(t: string) {
    this.registeredDialog.setModalInputButtonString(t);
  }
  setModalInput(t: boolean, m: string, b: string) {
    this.registeredDialog.setModalInput(t, m, b);
  }
  resetModal() {
    this.registeredDialog.resetModal();
  }

  showThis(t: string, m: string, c: boolean) {
    this.registeredDialog.setModal(t, m, c);
    return this.show();
  }

  showInput(t: boolean, m: string, b: string): Promise<{ text: string }> {
    this.registeredDialog.setModalInput(t, m, b);
    return this.show();
  }

  show(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.registeredDialog.show();
      this.registeredDialog.onConfirm.subscribe(() => {
        this.registeredDialog.hide();
        resolve();
      });
      this.registeredDialog.onCancel.subscribe(() => {
        this.registeredDialog.hide();
        reject();
      });
      this.registeredDialog.confirmInput.subscribe(x => {
        this.registeredDialog.hide();
        resolve(x);
      });
    });
  }
}
