import { Injectable } from "@angular/core";
import { InfoPopupComponent } from "./info-popup.component";

@Injectable({
  providedIn: "root",
})
export class InfoPopupService {
  registeredDialog: InfoPopupComponent;

  constructor() {}
  register(dialog: InfoPopupComponent) {
    this.registeredDialog = dialog;
  }
  showImage(title, imageUrl) {
    this.registeredDialog.setPropertiesImageDisplay(title, imageUrl);
    return this.show();
  }
  showList(markersList) {
    this.registeredDialog.setPropertiesListDisplay(markersList);
    return this.show();
  }
  show(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.registeredDialog.show();
      this.registeredDialog.onConfirm.subscribe(() => {
        this.registeredDialog.hide();
        resolve(null);
      });
      this.registeredDialog.onCancel.subscribe(() => {
        this.registeredDialog.hide();
        reject();
      });
      this.registeredDialog.confirmInput.subscribe((x) => {
        if (!x.update) this.registeredDialog.hide();
        resolve(x);
      });
    });
  }
}
