import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Camera, Permission } from "../video/video";
import { VideoService } from "../video/video.service";
import { InfoPopupComponent } from "../info-popup/info-popup.component";
import { InfoPopupService } from "../info-popup/info-popup.service";

@Component({
  selector: "app-map-page",
  templateUrl: "./map-page.component.html",
  styleUrls: ["./map-page.component.css"],
})
export class MapPageComponent implements OnInit {
  permissions: Permission;
  cameras;
  filteredCams;
  markers;
  filteredMarkers;
  setLat;
  setLong;
  setZoom;
  baseLat;
  baseLong;
  baseZoom;
  indexCams = true;
  showCamList = false;
  showImages = false;
  showInViewer = true;
  viewInNewWindow = false;
  useCamNameOnMap = false;
  manualMapMove = true;
  manualSearch = true;
  searchPlaceholder = "Search";
  minDist = 10.0;

  @ViewChild("searchBox", { static: false }) public searchBox: ElementRef;
  @ViewChild("iDialog", { static: true })
  public iDialog: InfoPopupComponent;
  constructor(
    private router: Router,
    private videoService: VideoService,
    private infoService: InfoPopupService
  ) {}

  ngOnInit(): void {
    this.infoService.register(this.iDialog);

    this.videoService.getPermissionsList().subscribe((p) => {
      this.permissions = p;
      this.videoService.getVideoList().subscribe((x) => {
        var tempCams = x
          .filter(
            (y) =>
              (p.superuser ||
                p.permissions.includes("allcameras") ||
                p.cameras.includes(y.id)) &&
              y.longitude &&
              y.latitude
          )
          .sort(function (a, b) {
            if (a.id < b.id) {
              return -1;
            } else if (b.id < a.id) {
              return 1;
            }
            return 0;
          });
        this.cameras = tempCams.map((x, i) => {
          var indexNum = (i + 1).toString();
          var idRef = x.id;
          var mapRef = indexNum;
          if (this.useCamNameOnMap) mapRef = idRef;
          var t = Object.assign(x, {
            name: x.id,
            id: mapRef,
            index: indexNum,
            labelOptions: {
              color: "#000000",
              fill: "#ef3f38",
              text: mapRef,
            },
            infoText: [x.id],
            infoIsOpen: false,
          });
          return t;
        });
        this.filteredCams = this.cameras;
        this.markers = [];
        var len = this.filteredCams.length;
        var excluded = [];
        for (var i = 0; i < len; i++) {
          if (excluded.includes(i)) continue;
          var t1 = Object.assign({}, this.filteredCams[i]);
          t1.emitClick = true;
          for (var j = i + 1; j < len; j++) {
            var t2 = this.filteredCams[j];
            var dist = this.haversineDist(
              t1.latitude,
              t1.longitude,
              t2.latitude,
              t2.longitude
            );
            if (dist < this.minDist) {
              t1.id += "," + t2.id;
              t1.labelOptions.text += "," + t2.labelOptions.text;
              t1.infoText.push(t2.name);
              t1.emitClick = false;
              excluded.push(j);
            }
          }
          this.markers.push(t1);
        }
        this.filteredMarkers = this.markers;
      });
    });
  }

  zoomTo(cam) {
    var _this = this;
    this.manualMapMove = false;
    this.setLat = cam.latitude;
    this.setLong = cam.longitude;
    this.setZoom = 20;
    setTimeout(() => {
      _this.manualMapMove = true;
    }, 3000);
  }

  markerClicked(e) {
    if (this.showImages)
      this.infoService.showImage(
        e.marker.name,
        `snapshot/${e.marker.name}.1.jpeg`
      );
    else if (this.showInViewer)
      if (e.marker.infoText.length == 1)
        if (this.viewInNewWindow)
          window.open("/#/video/viewer/" + e.marker.name);
        else this.router.navigateByUrl("/video/viewer/" + e.marker.name);
      else
        this.infoService
          .showList(e.marker.infoText)
          .then((res) => {})
          .catch((err) => {});
  }

  mapReady(e) {
    this.baseLat = e.centre.lat();
    this.baseLong = e.centre.lng();
    this.baseZoom = e.zoom;
  }

  viewChanged(e) {
    // Add map list filtering based on view bounds
    var _this = this;
    if (this.manualMapMove) {
      this.manualSearch = false;
      this.searchBox.nativeElement.value = "";
      this.searchPlaceholder = "-- Filtered By Map View --";
      this.filteredCams = this.cameras.filter((s) => {
        var latIn = s.latitude > e.minLat && s.latitude < e.maxLat;
        var longIn = s.longitude > e.minLong && s.longitude < e.maxLong;
        return latIn && longIn;
      });
      setTimeout(() => {
        _this.manualSearch = true;
      }, 3000);
    }
  }

  showAll() {
    this.setLat = this.baseLat;
    this.setLong = this.baseLong;
    this.setZoom = this.baseZoom;
  }

  search(q: string): void {
    if (this.manualSearch) {
      if (q == "") {
        this.filteredCams = this.cameras;
        this.filteredMarkers = this.markers;
      } else {
        this.filteredCams = this.cameras.filter((s) => {
          var matchName = s.name.includes(q);
          var matchLoc = s.location.includes(q);

          return matchLoc || matchName;
        });
        var filteredIDs = this.filteredCams.map((x) => x.id);
        this.filteredMarkers = this.markers.filter((s) => {
          var ids = s.id.split(",");
          var found = false;
          for (var i = 0; i < ids.length; i++)
            if (filteredIDs.includes(ids[i])) found = true;
          return found;
        });
      }
      this.searchPlaceholder = "Search";
    }
  }

  haversineDist(lat1, lon1, lat2, lon2) {
    var eR = 6371e3; // Radius of the earth in metres
    var p1 = this.deg2rad(lat1);
    var p2 = this.deg2rad(lat2);
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2.0) * Math.sin(dLat / 2.0) +
      Math.cos(p1) * Math.cos(p2) * Math.sin(dLon / 2.0) * Math.sin(dLon / 2.0);
    var c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return eR * c; // Distance in metres
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180.0); // Convert degrees to radians
  }
}
