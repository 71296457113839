import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";

@Component({
  selector: "app-mapviewer",
  templateUrl: "./mapviewer.component.html",
  styleUrls: ["./mapviewer.component.css"],
})
export class MapviewerComponent implements OnInit {
  @Output() dragEnd: EventEmitter<any> = new EventEmitter();
  @Output() mapIsReady: EventEmitter<any> = new EventEmitter();
  @Output() markerClick: EventEmitter<any> = new EventEmitter();
  @Output() viewChanged: EventEmitter<any> = new EventEmitter();
  @Input() markers;
  @Input() title: string;
  @Input() route: boolean;
  @Input() singleMarker: boolean;
  @Input() fitInitiallyOnly: boolean;
  @Input() travelModeIn: string;
  @Input() setLat: number;
  @Input() setLong: number;
  @Input() setZoom: number;
  @Input() hoverForInfo: boolean;
  @Input() openInNewWindow: boolean;
  showRoute = false;
  origin: any = {};
  destination: any = {};
  estimate = "";
  latitudeBase = 51.509865;
  longitudeBase = -0.118092;
  routeRedrawDelay = 100;
  markerMove = false;
  intervalTimer;
  keepFitting = true;
  travelMode: string = "DRIVING";
  mapLoaded;
  openInfoWindow;
  singleInfoWindowOnly = true;

  constructor() {}

  ngOnInit() {
    if (this.travelModeIn) this.travelMode = this.travelModeIn;
    if (this.route) {
      this.showRoute = true;
      this.origin = {
        id: this.markers[0].id,
        lat: this.markers[0].latitude,
        lng: this.markers[0].longitude,
      };

      const lastid = this.markers.length - 1;
      this.destination = {
        id: this.markers[lastid].id,
        lat: this.markers[lastid].latitude,
        lng: this.markers[lastid].longitude,
      };
    }
    if (this.route) {
      this.intervalTimer = setInterval(
        this.refreshRoute.bind(this),
        5 * this.routeRedrawDelay
      );
    }
  }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  }

  refreshRoute() {
    if (this.aMarkerHasMoved()) {
      this.origin = {
        id: this.markers[0].id,
        lat: this.markers[0].latitude,
        lng: this.markers[0].longitude,
      };
      const lastid = this.markers.length - 1;
      this.destination = {
        id: this.markers[lastid].id,
        lat: this.markers[lastid].latitude,
        lng: this.markers[lastid].longitude,
      };
      this.showRoute = false;
      this.estimate = null;
      setTimeout(() => {
        this.showRoute = true;
      }, this.routeRedrawDelay);
    }
  }

  markerClicked(marker, infoWindow) {
    if (marker.emitClick) this.markerClick.emit({ marker: marker });
    else {
      if (this.openInfoWindow && this.singleInfoWindowOnly) {
        this.openInfoWindow.close();
      }
      this.openInfoWindow = infoWindow;
      infoWindow.open();
    }
  }

  mapClicked(e) {
    if (this.openInfoWindow) {
      this.openInfoWindow.close();
      this.openInfoWindow = null;
    }
  }

  markerMoved(e, marker) {
    marker.latitude = e.coords.lat;
    marker.longitude = e.coords.lng;
    if (this.route) {
      if (marker.id == this.origin.id) {
        this.origin.lat = marker.latitude;
        this.origin.lng = marker.longitude;
        this.showRoute = false;
        this.estimate = null;
        this.markerMove = true;
        setTimeout(() => {
          this.showRoute = true;
        }, this.routeRedrawDelay);
      } else if (marker.id == this.destination.id) {
        this.destination.lat = marker.latitude;
        this.destination.lng = marker.longitude;
        this.showRoute = false;
        this.estimate = null;
        this.markerMove = true;
        setTimeout(() => {
          this.showRoute = true;
        }, this.routeRedrawDelay);
      }
    }
    this.dragEnd.emit({
      marker: marker,
      newLat: e.coords.lat,
      newLng: e.coords.lng,
    });
  }

  saveMap(map) {
    var _this = this;
    this.mapLoaded = true;
    if (this.fitInitiallyOnly)
      setTimeout(() => {
        _this.keepFitting = false;
        this.mapIsReady.emit({
          map: map,
          centre: map.getCenter(),
          zoom: map.getZoom(),
        });
      }, 5000);
    //this.mapIsReady.emit(map);
  }

  onResponse(e) {
    const t = e.routes[0].legs[0];
    //    console.log(t);
    this.estimate = `Estimated distance: ${t.distance.text}`;
    //, duration: ${t.duration.value} s`;
  }

  aMarkerHasMoved() {
    if (this.origin.lat != this.markers[0].latitude) return true;
    if (this.origin.lng != this.markers[0].longitude) return true;
    const lastid = this.markers.length - 1;
    if (this.destination.lat != this.markers[lastid].latitude) return true;
    if (this.destination.lng != this.markers[lastid].longitude) return true;
    return false;
  }

  boundsChanged(e) {
    if (this.mapLoaded)
      if (e && e.ub && e.Ra)
        if (e.ub.lo && e.ub.hi && e.Ra.lo && e.Ra.hi)
          this.viewChanged.emit({
            minLat: e.ub.lo,
            maxLat: e.ub.hi,
            minLong: e.Ra.lo,
            maxLong: e.Ra.hi,
          });
  }

  onMouseOver(infoWindow, $event: MouseEvent) {
    if (this.hoverForInfo) {
      if (this.openInfoWindow && this.singleInfoWindowOnly) {
        this.openInfoWindow.close();
        this.openInfoWindow = infoWindow;
      }
      infoWindow.open();
    }
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    if (this.hoverForInfo) {
      if (this.openInfoWindow && this.singleInfoWindowOnly) {
        this.openInfoWindow.close();
        this.openInfoWindow = null;
      }
      infoWindow.close();
    }
  }
}
