import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
  selector: "app-info-popup",
  templateUrl: "./info-popup.component.html",
  styleUrls: ["./info-popup.component.css"],
})
export class InfoPopupComponent implements OnInit {
  onConfirm: EventEmitter<any> = new EventEmitter();
  onCancel: EventEmitter<any> = new EventEmitter();
  confirmInput: EventEmitter<any> = new EventEmitter();
  private backdrop: HTMLElement;
  style: any;
  title: string;
  imageUrl: string;
  markersList: Array<any>;

  @HostListener("window:keydown", ["$event"])
  handleKeyDown(event: KeyboardEvent) {
    this.doc_keyDown(event);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient
  ) {}

  ngOnInit(): void {}

  setPropertiesImageDisplay(titleIn, imgaeUrlIn) {
    this.markersList = null;
    this.title = titleIn;
    this.imageUrl = imgaeUrlIn;
  }

  setPropertiesListDisplay(markersListIn) {
    this.imageUrl = null;
    this.title = null;
    this.markersList = markersListIn;
  }

  okClicked() {
    this.reset();
    this.onConfirm.emit();
  }

  cancelClicked() {
    this.reset();
    this.onCancel.emit();
  }

  confirmClicked() {
    this.reset();
    this.confirmInput.emit({});
  }

  reset() {
    this.title = null;
    this.imageUrl = null;
    this.markersList = null;
  }

  show() {
    this.document.body.classList.add("modal-open");
    this.style = { display: "block" };
    this.showBackdrop();
  }

  hide() {
    this.document.body.classList.remove("modal-open");
    this.style = { display: "none" };
    this.hideBackdrop();
  }

  showBackdrop() {
    this.backdrop = this.document.createElement("div");
    this.backdrop.classList.add("modal-backdrop");
    this.backdrop.classList.add("show");
    this.document.body.appendChild(this.backdrop);
  }

  hideBackdrop() {
    this.backdrop.remove();
  }

  doc_keyDown(e: KeyboardEvent) {
    var _this = this;
    if (e.key == "Escape") {
      e.preventDefault();
      this.okClicked();
    }
  }
}
