<div class="animated fadeIn" *ngIf="!userData && connectionTimedOut()">
  <p class="text-center h1 my-auto">
    <i class="fa fa-server fa-3x"></i><br />Remote Server Error
  </p>
</div>
<div class="animated fadeIn">
  <div class="row d-flex flex-row">
    <div class="col-md-9 align-self-start">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> User Permissions
          <div class="float-right">
            <button
              *ngIf="selectedUser"
              class="btn btn-primary"
              type="button"
              (click)="reload()"
              tooltip="Revert Changes"
              [delay]="500"
              [disabled]="!changesMade"
            >
              <i class="fa fa-refresh"></i>
            </button>
            <button
              class="btn btn-danger"
              type="button"
              (click)="cancelChanges()"
              tooltip="Clear User"
              [delay]="500"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
        <div class="card-body padding-0 for-overlaying">
          <table class="blankpage" *ngIf="!selectedUser">
            <tbody>
              <tr>
                <td class="message">
                  <h2>Select A User From The List On The Right</h2>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table for-use" [style.opacity]="selectedUser ? 1 : 0">
            <tbody>
              <tr>
                <td class="headercell">User:</td>
                <td>
                  <input
                    type="string"
                    name="userID"
                    [(ngModel)]="editingUser.id"
                    [disabled]="selectedUser || true"
                  />
                </td>
              </tr>
              <tr>
                <td class="headercell">Permissions:</td>
                <td>
                  <ul
                    *ngIf="editingUser.permissionsObj"
                    class="list-group w-100"
                  >
                    <ng-container *ngFor="let opt of permissions">
                      <li class="list-group-item" *ngIf="showOption(opt.name)">
                        <input
                          type="checkbox"
                          [checked]="editingUser.permissionsObj[opt.name]"
                          (change)="changePermisson(opt.name)"
                          [disabled]="
                            unEdittablePermission(opt.name) || !selectedUser
                          "
                        />
                        {{ optionDescription(opt.name) }}
                      </li>
                    </ng-container>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="headercell">
                  Cameras:
                </td>
                <td>
                  <ss-multiselect-dropdown
                    #camSelect
                    *ngIf="cameraOptions.length > 0"
                    style="width: 100%;"
                    [settings]="dropdownSettings"
                    [options]="cameraOptions"
                    [(ngModel)]="camerasSelected"
                    [disabled]="
                      editingUser.permissionsObj['allcameras'] || !selectedUser
                    "
                    tooltip="List of cameras the user is allowed to work with"
                    [delay]="500"
                  ></ss-multiselect-dropdown>
                </td>
              </tr>
              <!--
              <tr>
                <td class="headercell">
                  Walls:
                </td>
                <td>
                  <ss-multiselect-dropdown
                    #wallSelect
                    *ngIf="wallOptions.length > 0"
                    style="width: 100%;"
                    [settings]="dropdownSettings"
                    [options]="wallOptions"
                    [(ngModel)]="wallsSelected"
                    tooltip="List of video walls the user is allowed to work with"
                    [delay]="500"
                    [disabled]="!selectedUser"
                  ></ss-multiselect-dropdown>
                </td>
              </tr>
              -->
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="float-right">
            <button
              class="btn btn-primary"
              type="button"
              (click)="commitChanges()"
            >
              Submit Changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card-header">
        <div class="input-group">
          <input
            class="form-control"
            #searchBox
            placeholder="Search"
            id="search-box"
            (input)="search(searchBox.value)"
          />
          <span class="input-group-append">
            <button
              class="btn btn-primary"
              type="button"
              (click)="searchBox.value = ''"
            >
              Clear
            </button>
          </span>
        </div>
      </div>
      <div
        class="card-body scroll overflow-auto w-100"
        style="padding: 0px; height: 80vh;"
      >
        <ul class="list-group w-100">
          <li
            class="list-group-item"
            *ngFor="let user of userData"
            (dblclick)="doubleClick(user)"
            tooltip="Double click to select"
            [delay]="1000"
          >
            {{ user.id }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="showAddUser" class="row d-flex flex-row">
    <div class="col-md-9 align-self-start">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Add/Delete User
          <div class="float-right">
            <button
              class="btn btn-danger"
              type="button"
              (click)="hideAddUser()"
              tooltip="Clear User"
              [delay]="500"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
        <div class="card-body padding-0 for-overlaying">
          <table class="table">
            <tbody>
              <tr>
                <td class="newUserHeader">New User ID:</td>
                <td class="fillWidth">
                  <input
                    type="string"
                    class="fillWidth"
                    name="newUserID"
                    [(ngModel)]="newUserName"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="addNewUser()"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <tr>
                <td class="newUserHeader">User ID:</td>
                <td class="fillWidth">
                  <input
                    type="string"
                    class="fillWidth"
                    name="deleteUserID"
                    [(ngModel)]="deleteUserName"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="deleteUser()"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-flash-message #fmDialog></app-flash-message>
