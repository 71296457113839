<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/nowwireless.png',  height: 25, alt: 'Now Wireless Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/nowarrows.png', width: 30, height: 30, alt: 'Now Wireless Logo'}"
  [sidebarToggler]="'lg'"
  >
  <ul class="nav navbar-nav d-md-down-none">
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item d-md-down-none">
      <!-- <a class="nav-link" href="#"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span></a> -->
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div>
      <br>
    </div>
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- <cui-breadcrumb>
      <! -- Breadcrumb Menu-- >
      <li class="breadcrumb-menu d-md-down-none">
      </li>
    </cui-breadcrumb> -->
    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
      <!--<app-breadcrumb></app-breadcrumb>-->
      <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
      <!--<li class="breadcrumb-menu d-md-down-none">-->
        <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
          <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
          <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
          <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
        <!--</div>-->
      <!--</li>-->
    <!--</ol>-->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<!-- <app-footer>
  <span><a href="https://coreui.io">CoreUI</a> &copy; 2018 creativeLabs.</span>
  <span class="ml-auto">Powered by <a href="https://coreui.io/angular">CoreUI for Angular</a></span>
</app-footer> -->
